<div class="container text-xsmall">
  <div *ngFor="let item of listLink; let i = index">
    <div *ngIf="i == 0" class="flex-special">
      <p class="link" (click)="toLink(item.router)">
        {{ item.label }}
      </p>
      <label class="text-black ml-1 mr-1">-</label>
    </div>
    <p *ngIf="i == listLink.length - 1">{{ item.label }}</p>
  </div>
</div>
