import { Router } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';
import { LoginService } from 'projects/agencia/src/app/auth/services/login.service';

@Component({
  selector: 'navbar-trans',
  templateUrl: './navbar-trans.component.html',
  styleUrls: ['./navbar-trans.component.scss'],
})
export class NavbarTransComponent implements OnInit {
  @Input() nameUser;

  constructor(public route: Router, public apiLoginAgencia: LoginService) {}

  ngOnInit(): void {
    console.log(this.nameUser);
  }

  login() {
    this.route.navigate(['/login']);
  }

  logout() {
    this.apiLoginAgencia.logout().subscribe(
      (resp) => {
        console.log(resp);
        localStorage.removeItem('token');
        this.route.navigate(['/login']);
      },
      (error) => {
        console.log(error);
      }
    );
  }
}
