import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { GeneralModalComponent } from '../general-modal/general-modal.component';

@Component({
  selector: 'app-acept-terms-modal',
  templateUrl: './acept-terms-modal.component.html',
  styleUrls: ['./acept-terms-modal.component.scss'],
})
export class AceptTermsModalComponent implements OnInit {
  checked;
  radioValue;
  listConditions = [
    { id: 0 },
    { id: 1 },
    { id: 2 },
    { id: 3 },
    { id: 4 },
    { id: 5 },
  ];
  loading: boolean = false;
  formConditions: FormGroup;
  validForm: boolean = false;

  constructor(
    public fb: FormBuilder,
    private modal: NzModalRef,
    private modalService: NzModalService
  ) {
    this.formConditions = this.fb.group({});
  }

  ngOnInit(): void {
    this.listConditions.forEach((element) => {
      this.formConditions.addControl(
        'radio-' + element.id,
        this.fb.control([null])
      );
    });
    this.loading = true;

    this.formConditions.valueChanges.subscribe((x) => {
      // console.log(x);
      this.validForm = Object.values(x).every((x) => x === 'S');
    });
  }

  get f() {
    return this.formConditions.controls;
  }

  get option() {
    return this.f.option as FormArray;
  }

  ngModelChange(e) {
    console.log(e);
  }

  submit() {
    this.modal.destroy();
    let aprobado = {
      text: 'Su registro ha sido recibido con éxito, y ya puedes empezar a usar nuestra plataforma y obtener tus ganancias como Agencia Recaudadora!',
      title: 'GENIAL!',
      subtitle1: 'Puedes empezar a operar como',
      subtitle2: 'AGENCIA ESTÁNDAR',
      buttonSecondary: 'Quiero ser Agencia Premium',
      buttonOutline: 'Comenzar ahora',
    };
    this.modalService.create({
      nzMaskClosable: false,
      nzCancelText: null,
      nzOkText: null,
      nzClosable: false,
      nzComponentParams: { aprobado },
      nzStyle: {},
      nzWidth: 455,
      nzFooter: null,
      nzBodyStyle: {
        padding: '20px',
        color: '#707070',
      },
      nzContent: GeneralModalComponent,
    });
  }
}
