import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RegisterServiceService } from '../service/register-service.service';

@Component({
  selector: 'app-view-pdf',
  templateUrl: './view-pdf.component.html',
  styleUrls: ['./view-pdf.component.scss'],
})
export class ViewPdfComponent implements OnInit {
  pdfSrc: string;
  document: string;
  constructor(
    public apiRegister: RegisterServiceService,
    public _route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this._route.paramMap.subscribe((params) => {
      console.log(params.get('doc'));

      if (params.get('doc') === 'terminos-condiciones') {
        this.apiRegister.getTermsAndConditions().subscribe(
          (resp) => {
            this.pdfSrc = resp.urlFile;
          },
          (error) => {
            console.log(error);
          }
        );
      } else {
        this.apiRegister.getContract().subscribe(
          (resp) => {
            this.pdfSrc = resp.urlFile;
          },
          (error) => {
            console.log(error);
          }
        );
      }
    });
  }
}
