import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'projects/agencia/src/environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  _baseURL: string = environment.base;
  optionRecovery: number;

  constructor(private http: HttpClient) {}

  getToken() {
    return localStorage.getItem('token');
  }

  setToken(token) {
    localStorage.setItem('token', token);
  }

  loginAgencia(body): Observable<any> {
    const url = `${this._baseURL}/login/register`;

    return this.http
      .post(url, body, {
        headers: new HttpHeaders({
          Accept: '*/*',
        }),
      })
      .pipe(map((data) => data));
  }

  getDetalisAgency(): Observable<any> {
    const url = `${this._baseURL}/register/agency/detail`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  recoveryEmail(body): Observable<any> {
    const url = `${this._baseURL}/login/problems/temp-block`;

    return this.http
      .post(url, body, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: 'this.getToken()',
        }),
      })
      .pipe(map((data) => data));
  }

  recoveryPasswordBlockTemp(body, id): Observable<any> {
    const url = `${this._baseURL}/login/problems/temp-block/newpass/${id}`;

    return this.http
      .post(url, body, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: 'this.getToken()',
        }),
      })
      .pipe(map((data) => data));
  }

  logout(): Observable<any> {
    const url = `${this._baseURL}/dashboard/logout`;

    return this.http
      .post(
        url,
        {},
        {
          headers: new HttpHeaders({
            Accept: '*/*',
            Authorization: this.getToken(),
          }),
        }
      )
      .pipe(map((data) => data));
  }
}
