<div class="form-control file-box" *ngIf="!fileExist?.size">
  <input
    (change)="fileChangeEvent($event.target.files)"
    #uploader
    type="file"
    name="myImage"
  />
  <span *ngIf="fileExist" class="text-over-file">{{ file?.name }} </span>
  <span class="text-over-title">{{ title }}</span>
  <i (click)="open(uploader)" class="fas fa-folder-open"></i>
</div>
<div class="form-control file-box" *ngIf="fileExist?.size">
  <input
    (change)="fileChangeEvent($event.target.files)"
    #uploader
    type="file"
    name="myImage"
  />
  <span class="text-over-file">{{ fileExist?.name }} </span>
  <span class="text-over-title">{{ title }}</span>
  <i (click)="open(uploader)" class="fas fa-folder-open"></i>
</div>
