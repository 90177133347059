import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Observable, Observer } from 'rxjs';

@Component({
  selector: 'file-drag',
  templateUrl: './file-drag.component.html',
  styleUrls: ['./file-drag.component.scss'],
})
export class FileDragComponent implements OnInit {
  @Input() data;
  @Input() index;
  @Input() viewData;
  @Input() iconType;
  @Input() FileLoad;
  @Output() sendListDocument = new EventEmitter<any>();
  fileType: string = '.doc,docx,.pdf,rar,.xlsx,.xls,.png'; //file type
  listFile = [];

  constructor(private msg: NzMessageService) {}

  ngOnInit(): void {
    if (this.FileLoad) this.listFile = this.FileLoad;
  }

  beforeUploadProduct = (file: any) =>
    new Observable((observer: Observer<boolean>) => {
      let formValid = this.data.format.toLowerCase();
      let format = file.name.split('.').pop().toLowerCase();

      let sizeInMB = (file.size / (1024 * 1024)).toFixed(2);
      let maxSize = this.data.maxSizeMb;
      if (!formValid.includes(format)) {
        this.msg.error(`Solo permite formato ${formValid}`);
        observer.complete();
        return;
      }

      if (parseFloat(sizeInMB) > maxSize) {
        this.msg.error(
          `${file.name} El tamaño máximo por documento es ${maxSize} MB.`
        );
        observer.complete();
        return;
      }
      this.listFile = [];
      this.msg.success(`${file.name} Archivo cargado exitosamente.`);
      this.listFile.push({
        name: file.name,
        file: file,
        id: this.data.fieldId.toString(),
      });

      this.sendListDocument.emit({
        listFile: this.listFile,
        indice: this.index,
      });
    });

  deletedFile(index) {
    this.listFile.splice(index, 1);

    this.sendListDocument.emit({
      listFile: this.listFile,
      indice: this.index,
    });
  }
}
