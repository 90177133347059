import {
  FormGroup,
  FormBuilder,
  FormArray,
  FormControl,
  Validators,
} from '@angular/forms';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { setFloatToString } from 'src/functions/common-functions';
import { fvalidatorFactura } from 'src/functions/custom-validators';

@Component({
  selector: 'table-editable',
  templateUrl: './table-editable.component.html',
  styleUrls: ['./table-editable.component.scss'],
})
export class TableEditableComponent implements OnInit {
  @Input() rows;
  @Input() tableHeaders;
  @Input() isCheckbox;

  @Output() sendRowData = new EventEmitter<any>();
  @Output() eventAction = new EventEmitter<any>();
  @Output() eventUpdateValue = new EventEmitter<any>();
  @Output() eventDataSelected = new EventEmitter<any>();

  checked = false;
  indeterminate = false;
  listOfCurrentPageData: any[] = [];
  setOfCheckedId = new Set();
  arrayData: any[] = [];
  formTableEditable: FormGroup;

  constructor(public fb: FormBuilder) {
    this.formTableEditable = this.fb.group({
      valores: this.fb.array([]),
    });
  }

  ngOnInit(): void {
    this.rows.forEach((element) => {
      let valor = this.fb.group({
        valorAseo: new FormControl(
          setFloatToString(element.valorAseo),
          Validators.compose([fvalidatorFactura(), Validators.required])
        ),
        valorFactura: new FormControl(
          setFloatToString(element.valorFactura),
          Validators.compose([fvalidatorFactura(), Validators.required])
        ),
      });
      this.valores.push(valor);
    });
  }

  getValidFacture(i, control) {
    return this.valores.controls[i].get(control).invalid;
  }

  get valores(): FormArray {
    return this.formTableEditable.get('valores') as FormArray;
  }

  onCurrentPageDataChange(e): void {
    this.listOfCurrentPageData = e;
    this.refreshCheckedStatus();
  }

  refreshCheckedStatus(): void {
    const listOfEnabledData = this.listOfCurrentPageData.filter(
      ({ disabled }) => !disabled
    );

    this.checked = listOfEnabledData.every((item) => {
      this.setOfCheckedId.has(item.id);
    });
    this.indeterminate =
      listOfEnabledData.some((item) => this.setOfCheckedId.has(item.id)) &&
      !this.checked;
  }

  unsorted() {}

  onItemChecked(index, e, data) {
    this.updateCheckedSet(index, e, data);
    this.refreshCheckedStatus();
  }

  updateCheckedSet(id: number, checked: boolean, data) {
    // this.arrayData = [];
    // this.setOfCheckedId.clear();

    if (checked) {
      this.setOfCheckedId.add(id);
      this.arrayData.push(data);
    } else {
      this.setOfCheckedId.delete(id);
      let findIndex = this.arrayData.findIndex((x) => x.id === id);
      this.arrayData.splice(findIndex, 1);
    }
    console.log('Data para trabajar', this.arrayData);
    this.eventDataSelected.emit(this.arrayData);
  }

  isObject(obj) {
    return typeof obj === 'object';
  }

  isEditable(obj) {
    if (obj === 'valorAseo' || obj === 'valorFactura') {
      return true;
    } else {
      return false;
    }
  }

  selectedRow(data) {
    this.sendRowData.emit(data);
  }

  actionEmit(data) {
    console.log(data);

    this.eventAction.emit(data);
  }

  updateValue(control, position) {
    let updateValue = this.valores.controls[position].get(control).value;
    if (updateValue === '') {
      updateValue = '0';
    }
    this.eventUpdateValue.emit({
      position: position,
      updateValue: parseFloat(updateValue),
      key: control,
    });
    this.valores.controls[position]
      .get(control)
      .setValue(setFloatToString(updateValue));
    this.eventDataSelected.emit(this.arrayData);
  }
}
