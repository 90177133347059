import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class ErrorComponent implements OnInit {
  constructor(public route: Router) {}

  ngOnInit(): void {}

  toLogin() {
    localStorage.removeItem('token');
    this.route.navigate(['/login']);
  }
}
