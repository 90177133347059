export function validateInputs(forms) {
  for (const i in forms.controls) {
    if (forms.controls[i]) {
      forms.controls[i].markAsDirty();
      forms.controls[i].updateValueAndValidity();
    }
  }
}

export function setFloatToString(value) {
  let valueTransform = value.toString().replace('.', ',');
  return valueTransform;
}
