<nz-table
  #rowSelectionTable
  nzShowSizeChanger
  [nzData]="rows"
  [nzShowSizeChanger]="false"
  [nzSimple]="false"
  [nzPageSize]="5"
  (nzCurrentPageDataChange)="onCurrentPageDataChange($event)"
>
  <thead>
    <tr>
      <th *ngIf="isCheckbox"></th>
      <th nzAlign="center" *ngFor="let header of tableHeaders">
        <p>{{ header.label }}</p>
      </th>
      <th>Acciones</th>
    </tr>
  </thead>
  <tbody>
    <tr
      [ngClass]="{ styleTable: i % 2 === 0 }"
      *ngFor="let dataRow of rowSelectionTable.data; let i = index"
      (click)="selectedRow(dataRow)"
    >
      <td
        *ngIf="isCheckbox"
        [nzChecked]="setOfCheckedId.has(dataRow.id)"
        (nzCheckedChange)="onItemChecked(dataRow.id, $event, dataRow)"
      ></td>

      <td
        nzAlign="center"
        *ngFor="let item of dataRow | keyvalue: unsorted; let first = first"
        [ngClass]="{ first: first }"
      >
        <!-- Pinta los estilos si es un objeto -->
        <div *ngIf="isObject(item.value)">
          <div
            *ngIf="item.value.icono"
            class="icono"
            [ngClass]="{ iconDisable: item.value.disabled }"
            (click)="actionEmit(dataRow)"
            [ngStyle]="{ color: item.value.color }"
          >
            <i [class]="item.value.icono"></i>
          </div>

          <div *ngIf="!item.value.icono">
            <div
              class="estado"
              [ngStyle]="{
                color: item.value.color,
                'background-color': item.value.background
              }"
            >
              <p>{{ item.value.nombre }}</p>
            </div>
          </div>
        </div>
        <div *ngIf="isObject(item.value) === false">
          <div *ngIf="isEditable(item.key) === true">
            <form [formGroup]="formTableEditable">
              <div formArrayName="valores">
                <div [formGroupName]="i">
                  <input
                    [ngClass]="{ error: getValidFacture(i, item.key) }"
                    nz-input
                    mask="separator ,10"
                    thousandSeparator="."
                    prefix="$ "
                    placeholder=""
                    (blur)="updateValue(item.key, i)"
                    [formControlName]="item.key"
                  />
                </div>
              </div>
            </form>
          </div>
          <div *ngIf="isEditable(item.key) === false">
            {{ item.value }}
          </div>
        </div>
      </td>
    </tr>
  </tbody>
</nz-table>
