<div class="navbar">
  <div class="logo">
    <img src="/assets/img/Logo.svg" />
    <p class="text-bold text-primary text-medium">PAGO FÁCIL V3</p>
  </div>

  <div class="nav-list">
    <div class="item">
      <span>Coguasimales Services</span>
    </div>
    <div class="item">
      <span>Redsuelva</span>
    </div>
    <div class="item">
      <span>Contáctenos</span>
    </div>

    <div class="item">
      <button
        *ngIf="!nameUser"
        (click)="login()"
        class="btn btn-outline-primary"
      >
        Iniciar Sesión
      </button>
      <div class="text-black" *ngIf="nameUser">
        <a nz-dropdown [nzDropdownMenu]="menu">
          {{ nameUser }}
          <i nz-icon nzType="down"></i>
        </a>

        <nz-dropdown-menu #menu="nzDropdownMenu">
          <ul nz-menu nzSelectable>
            <li nz-menu-item (click)="logout()">Cerrar Sesión</li>
          </ul>
        </nz-dropdown-menu>
      </div>
    </div>
  </div>
</div>
