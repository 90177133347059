import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'projects/agencia/src/environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AutoRegistro } from '../model/autoRegistro';

@Injectable({
  providedIn: 'root',
})
export class RegisterServiceService {
  _baseURL: string = environment.base;
  toPremium: boolean;
  dataAutoRegistro: AutoRegistro;

  constructor(private http: HttpClient) {}

  getToken() {
    return localStorage.getItem('token');
  }

  setToken(token) {
    localStorage.setItem('token', token);
  }

  loginAgencia(body): Observable<any> {
    const url = `${this._baseURL}/login/register`;

    return this.http
      .post(url, body, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: 'this.getToken()',
        }),
      })
      .pipe(map((data) => data));
  }

  createAgency(body): Observable<any> {
    const url = `${this._baseURL}/register/agency`;

    return this.http
      .post(url, body, {
        headers: new HttpHeaders({
          Accept: '*/*',
          // Authorization: 'this.getToken()',
        }),
      })
      .pipe(map((data) => data));
  }

  sendCode(body, id): Observable<any> {
    const url = `${this._baseURL}/register/agency/${id}/phone`;

    return this.http
      .post(url, body, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: 'this.getToken()',
        }),
      })
      .pipe(map((data) => data));
  }

  verifyCode(body, id): Observable<any> {
    const url = `${this._baseURL}/register/agency/${id}/phone-verify`;

    return this.http
      .post(url, body, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: 'this.getToken()',
        }),
      })
      .pipe(map((data) => data));
  }

  sendEmail(body, id): Observable<any> {
    const url = `${this._baseURL}/register/agency/${id}/email`;

    return this.http
      .post(url, body, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: 'this.getToken()',
        }),
      })
      .pipe(map((data) => data));
  }

  verifyCodeEmail(body, id): Observable<any> {
    const url = `${this._baseURL}/register/agency/${id}/email-verify`;

    return this.http
      .post(url, body, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: 'this.getToken()',
        }),
      })
      .pipe(map((data) => data));
  }

  getTypeDocument(): Observable<any> {
    const url = `${this._baseURL}/register/identification-types`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: '*/*',
        }),
      })
      .pipe(map((data) => data));
  }

  getSexo(): Observable<any> {
    const url = `${this._baseURL}/register/genders`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: '*/*',
        }),
      })
      .pipe(map((data) => data));
  }

  getDetalisAgency(): Observable<any> {
    const url = `${this._baseURL}/register/agency/detail`;
    return this.http
      .get(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  saveDataRepresent(body): Observable<any> {
    const url = `${this._baseURL}/register/agency/legal-person`;

    return this.http
      .post(url, body, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  saveLocation(body): Observable<any> {
    const url = `${this._baseURL}/register/agency/address`;

    return this.http
      .post(url, body, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  getDocSolic(): Observable<any> {
    const url = `${this._baseURL}/register/agency/support-docs`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  saveDocSolic(body): Observable<any> {
    const url = `${this._baseURL}/register/agency/support-docs`;

    var formData = new FormData();

    for (let index = 0; index < body.length; index++) {
      formData.append('fotoFachada[' + index + ']', body[index].file);
      formData.append('fieldId', body[index].id);
    }
    return this.http
      .post(url, formData, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  getListTerms(): Observable<any> {
    const url = `${this._baseURL}/register/agency/terms-conditions`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  getContract(): Observable<any> {
    const url = `${this._baseURL}/register/agency/commercial-agreement`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  saveListTerms(body): Observable<any> {
    const url = `${this._baseURL}/register/agency/terms-conditions`;

    return this.http
      .post(url, body, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  getListToPremium(): Observable<any> {
    const url = `${this._baseURL}/dashboard/premium/apply`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  saveInfoPremium(body): Observable<any> {
    const url = `${this._baseURL}//dashboard/premium/apply`;

    var formData = new FormData();

    formData.append('commercialName ', body.comercialName);
    formData.append('nit ', body.nit);
    formData.append('camaraDeComercio  ', body.camaraDeComercio);
    formData.append('constDate', body.constDate);
    return this.http
      .post(url, formData, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  getListCity(params): Observable<any> {
    const url = `${this._baseURL}/register/cities`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
        params,
      })
      .pipe(map((data) => data));
  }

  getListDepart(): Observable<any> {
    const url = `${this._baseURL}/register/states`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  dataBasicPremium(): Observable<any> {
    const url = `${this._baseURL}/dashboard/premium/basic-data`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  saveDataBasicPremium(body): Observable<any> {
    const url = `${this._baseURL}/dashboard/premium/save-data`;

    return this.http
      .post(url, body, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  getFilesIdenty(): Observable<any> {
    const url = `${this._baseURL}/dashboard/premium/files-identity`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  saveFilesPremium(body): Observable<any> {
    const url = `${this._baseURL}/dashboard/premium/upload-files`;

    var formData = new FormData();

    for (let index = 0; index < body.length; index++) {
      let element = body[index];

      if (element.length > 0) {
        let agencyFileKey = 'agencyFile[' + index + ']';
        let fieldIdKey = 'fieldId[' + index + ']';
        formData.append(agencyFileKey, element[0].file);
        formData.append(fieldIdKey, element[0].id);
      }
    }

    return this.http
      .post(url, formData, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  getPicturesPremium(): Observable<any> {
    const url = `${this._baseURL}/dashboard/premium/agency-pictures`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  aplicPremium(): Observable<any> {
    const url = `${this._baseURL}/dashboard/premium/apply-request`;

    return this.http
      .post(
        url,
        {},
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Accept: '*/*',
            Authorization: this.getToken(),
          }),
        }
      )
      .pipe(map((data) => data));
  }

  getTermsAndConditions(): Observable<any> {
    const url = `${this._baseURL}/register/terms-conditions`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }
}
