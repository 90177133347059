<div class="container-detalle" nz-row nzAlign="top" nzGutter="28">
  <div nz-col nzSpan="15" class="gutter-row">
    <div class="section-title">
      <div class="title-package">
        <img src="/assets/img/imgClaro.jpg" alt="" />
        <p class="text-title text-black text-bold">{{ title }}</p>
      </div>
      <div>
        <p>Buscar</p>
      </div>
    </div>

    <div class="mt-3">
      <div class="section-filter">
        <div *ngFor="let filter of listFilter; let i = index">
          <div
            [ngClass]="filter.selected ? 'item-filter-active' : 'item-filter'"
            class="item-filter"
            (click)="selectFilter(i)"
          >
            <p class="text-bold">{{ filter.label }}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-3">
      <table-single-select
        [tableHeaders]="tableHeaders"
        [rows]="rows"
      ></table-single-select>
    </div>
  </div>

  <div nz-col nzSpan="9" class="gutter-row">
    <p class="text-title text-black text-bold">Ilimitado Claro 3 Dias</p>

    <div class="mt-3">
      <p>
        Min ilimitados TD 350Mb Redes sociales ilimitados WS FB TW SMS Ilimitado
        todo operador con Vig de 3 días
      </p>
    </div>

    <div class="mt-3" nz-row nzJustify="end">
      <div nz-col>
        <p class="text-bold text-primary">Valor a pagar $3.000,00</p>
        <p class="text-primary">Incentivo del 0% ($0,00)</p>
      </div>
    </div>

    <div class="mt-3" nz-row>
      <div nz-col>
        <label>Número de Celular</label>
        <input
          nz-input
          class="mt-1"
          nzSize="large"
          placeholder="Número de celular"
        />
      </div>
    </div>

    <div class="mt-3" nz-row nzAlign="middle">
      <div nz-col>
        <nz-switch [nzSize]="'small'" class="mr-1"></nz-switch>
        <span class="text-xsmall">
          Enviar Soporte de Compra por Correo Electrónico
        </span>
      </div>
    </div>

    <div class="mt-3" nz-row nzAlign="middle">
      <div nz-col>
        <label>Correo Electrónico del Comprador</label>
        <input
          class="mt-1"
          nz-input
          nzSize="large"
          placeholder="Email del Comprador"
        />
      </div>
    </div>

    <div nz-row class="mt-5" nzJustify="end">
      <button nz-button nzType="primary" nzSize="large">Agregar</button>
    </div>
  </div>
</div>
