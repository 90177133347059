import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'siderbar-menu',
  templateUrl: './siderbar-menu.component.html',
  styleUrls: ['./siderbar-menu.component.scss'],
})
export class SiderbarMenuComponent implements OnInit {
  @Input() menuLeft;
  @Output() collapseValue = new EventEmitter<any>();
  isCollapsed: boolean;
  optionMenu: any = [];
  constructor(public route: Router) {}

  ngOnInit(): void {}

  collapse() {
    this.isCollapsed = !this.isCollapsed;
    this.collapseValue.emit(this.isCollapsed);
  }

  selectMenu(option) {
    if (option.isEnabled) this.route.navigate([option.link]);
  }
}
