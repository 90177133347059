import { Router } from '@angular/router';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ModalErrorComponent } from 'src/app/shared/Modals/modal-error/modal-error.component';

@Injectable({
  providedIn: 'root',
})
export class InterceptorService implements HttpInterceptor {
  constructor(private modalService: NzModalService, public route: Router) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    console.log(req);

    const headers = this.getHeaders(req);

    req = req.clone({ headers });

    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        return this.controlError(error);
      })
    );
  }

  getHeaders(req: HttpRequest<any>): HttpHeaders {
    const token = localStorage.getItem('token');
    let headers;

    if (token) {
      headers = new HttpHeaders({
        Accept: '*/*',
        Authorization: token,
      });
    } else {
      headers = new HttpHeaders({
        Accept: '*/*',
      });
    }

    return headers;
  }

  controlError(error: HttpErrorResponse) {
    // console.log(error.status);
    // debugger;

    if (error.status >= 500) {
      this.route.navigate(['/error']);
    } else {
      let msjError = {
        text: error.error.message,
      };
      this.modalService.create({
        nzMaskClosable: false,
        nzCancelText: null,
        nzOkText: null,
        nzClosable: false,
        nzStyle: {},
        nzComponentParams: { msjError },
        nzWidth: 400,
        nzFooter: null,
        nzBodyStyle: {
          padding: '20px',
          color: '#707070',
        },
        nzContent: ModalErrorComponent,
      });
    }

    return throwError(error);
  }
}
