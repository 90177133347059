import { AgenciaService } from './../../../../projects/coguasimales/src/app/agencia/services/agencia.service';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit {
  @Input() listLink;

  constructor(public route: Router, public apiAgencia: AgenciaService) {}

  ngOnInit(): void {}

  toLink(router) {
    this.route.navigate([router]);
    this.apiAgencia.isAgencyBlock = false;
  }
}
