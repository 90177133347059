<div class="container">
  <div nz-row nzAlign="middle">
    <div nz-col>
      <p class="text-title text-bold text-primary">Resumen de compra</p>
    </div>
  </div>

  <div class="list-product">
    <div class="content" *ngFor="let item of listDataProduct; let i = index">
      <div class="mt-4">
        <info-purchase
          [info]="item"
          [index]="i"
          (deleteProductEmitter)="deleteProductEvent($event)"
        ></info-purchase>
      </div>
    </div>
  </div>
</div>

<div class="sectionPay">
  <div class="text-right text-black">
    <p>Subtotal</p>
    <p class="mt-1">IVA</p>
    <p class="mt-1">Descuentos</p>
    <p class="text-black text-bold text-medium mt-1">Total a pagar</p>
  </div>
  <div class="text-right text-black">
    <p>
      ${{ subTotal | currency: " ":"symbol":"1.0-2":"es" }},<span
        class="text-xsmall"
        >{{ subTotalDec }}</span
      >
    </p>
    <p class="mt-1">
      ${{ iva | currency: " ":"symbol":"1.0-2":"es" }},<span
        class="text-xsmall"
        >{{ ivaDec }}</span
      >
    </p>
    <p class="mt-1">
      ${{ descuentos | currency: " ":"symbol":"1.0-2":"es" }},<span
        class="text-xsmall"
        >{{ descuentosDec }}</span
      >
    </p>
    <p class="text-black text-bold text-medium mt-1">
      ${{ total | currency: " ":"symbol":"1.0-2":"es" }},<span
        class="text-xsmall"
        >{{ totalDec }}</span
      >
    </p>
  </div>
</div>

<div class="buttonPay">
  <p class="text-title text-bold">Finalizar y Pagar</p>
</div>
